<template>
	<div class="mtipytamequipamientoFormHeader puiformheader">
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mtipytamequipamiento.idtiptam')" :value="getIdtiptamValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mtipytamequipamiento.codtiptamedi')" :value="getCodtiptamediValue"></pui-field>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<pui-field :label="$t('header.mtipytamequipamiento.name')" :value="getNameValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'mtipytamequipamiento-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'mtipytamequipamiento'
		};
	},
	computed: {
		getIdtiptamValue() {
			return this.model && this.model.idtiptam ? this.model.idtiptam : '-';
		},
		getCodtiptamediValue() {
			return this.model && this.model.codtiptamedi ? this.model.codtiptamedi : '-';
		},
		getNameValue() {
			return this.model && this.model.name ? this.model.name : '-';
		},
	}
}
</script>
